import React from "react"
import styled from "@emotion/styled"
// import {useLocale} from "@shockventures/gatsby-theme-common";
import {Container, Row, Col} from "@shockventures/gatsby-theme-common/src/components/Grid"
import {Links} from "@shockventures/gatsby-theme-common/src/components/Footer"
import SocialMediaLinks from "@shockventures/gatsby-theme-common/src/components/SocialMediaLinks"
// import MailchimpSubscribe from "react-mailchimp-subscribe"

const Footer = styled.footer`
  padding: 5vh 12px;
  border-top: 10px solid #EFEFEF;
`
// const MailchimpSubscribeForm = ({status, message, onValidated}) => {
//   let email;
//   const locale = useLocale();
//   const submit = () =>
//       email &&
//       email.value.indexOf("@") > -1 &&
//       onValidated({
//         EMAIL: email.value,
//       });
//   const style = {margin: '-10px', marginTop: 10, padding: "4px 8px", backgroundColor: '#EFEFEF', fontSize: '9px'};
//   return (
//       <div style={{marginTop: 35, textAlign: "center", position: "relative"}}>
//         <div style={{position: 'absolute', marginTop: '-14px', left: 0, right: 0, textTransform: 'uppercase',}}>
//           <h5
//               style={{
//                 display: 'inline-block',
//                 fontSize: '11px',
//                 paddingLeft: 10,
//                 paddingRight: 5,
//                 letterSpacing: 5,
//                 backgroundColor: 'white',
//                 border: '1px solid #EFEFEF',
//                 color: '#CCC',
//                 textAlign: "center",
//               }}>Newsletter</h5></div>
//         <div style={{
//
//           textAlign: "center",
//           fontSize: '11px',
//           border: '1px solid #EFEFEF',
//           padding: 10,
//           display: "inline-block",
//           marginLeft: 'auto',
//           marginRight: 'auto'
//         }}>
//           <input
//               style={{
//                 marginTop: 8,
//                 padding: '4px 8px',
//                 border: '1px solid #EFEFEF',
//                 borderRight: 1,
//                 textTransform: 'uppercase'
//               }}
//               ref={node => (email = node)}
//               type="email"
//               placeholder="E-Mail"
//           />
//           <button style={{padding: '4px 8px', border: '1px solid #EFEFEF', backgroundColor: '#EFEFEF'}}
//                   onClick={submit}>
//             {locale.locale === "de" ? "ANMELDEN" : "SUBSCRIBE"}
//           </button>
//           {status === "sending" && <div style={style}>sending...</div>}
//           {status === "error" && (<div style={style} dangerouslySetInnerHTML={{__html: message}}/>)}
//           {status === "success" && (<div style={style} dangerouslySetInnerHTML={{__html: message}}/>)}
//
//         </div>
//       </div>
//   );
// };

export default ({siteContext}) => (
    <Footer>
      <Container>
        <Row>
          <Col>
            <Links links={siteContext.siteLayout.secondary.links}/>
          </Col>
        </Row>
        <Row>
          <Col>
            <SocialMediaLinks connectedAccounts={siteContext.connectedAccounts}/>
          </Col>
        </Row>
        {/*<Row>*/}
        {/*  <Col><MailchimpSubscribe*/}
        {/*      url={siteContext.mailchimp.url}*/}
        {/*      render={({subscribe, status, message}) => (<MailchimpSubscribeForm status={status}*/}
        {/*                                                                         message={message}*/}
        {/*                                                                         onValidated={formData => subscribe(formData)}/>)}*/}
        {/*  /></Col>*/}
        {/*</Row>*/}
      </Container>
    </Footer>
)